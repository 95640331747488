<template>
  <el-tooltip
    class="item"
    effect="dark"
    style="margin-end: 0.1rem"
    :content="
      isAge
        ? formatDate(timestamp, 'yyyy-MM-dd hh:mm:ss')
        : formateAge(timestamp)
    "
    placement="top"
  >
    <span
      rel="tooltip"
      data-toggle="tooltip"
      data-placement="bottom"
      title=""
      >{{
        isAge
          ? formateAge(timestamp)
          : formatDate(timestamp, "yyyy-MM-dd hh:mm:ss")
      }}</span
    >
  </el-tooltip>
</template>
<script>
import { getName } from "../../../public/assets/js/lang.js";
export default {
  props: {
    timestamp: "",
    isAge: false,
  },
  methods: {
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    formateAge(timestamp) {
      if (!isNaN(timestamp)) {
        const myDate = new Date();
        const now = myDate.getTime();
        const dis = now - timestamp * 1000;
        var time = "--:--";
        var t = 0;
        if (dis < 0) {
          time = 0 + " " + getName("Home", "sec");
        } else if (dis < 60000) {
          t = parseInt(dis / 1000);
          time = t + " " + getName("Home", t > 1 ? "secs" : "sec");
        } else if (dis < 60 * 1000 * 60) {
          t = parseInt(dis / 1000 / 60);
          time = t + " " + getName("Home", t > 1 ? "mins" : "min");
        } else if (dis < 24 * 60 * 1000 * 60) {
          t = parseInt(dis / 1000 / 60 / 60);
          time = t + " " + getName("Home", t > 1 ? "hours" : "hour");
        } else if (dis < 365 * 24 * 60 * 1000 * 60) {
          t = parseInt(dis / 1000 / 60 / 60 / 24);
          time = t + " " + getName("Home", t > 1 ? "days" : "day");
        } else {
          t = parseInt(dis / 1000 / 60 / 60 / 24 / 365);
          time = t + " " + getName("Home", t > 1 ? "years" : "year");
        }
        time = time + " " + getName("Home", "ago");
      }
      return time;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
  },
};
</script>
