<template>
  <!-- show this is a contract (token) should show all -->
  <span style="white-space: nowrap" data-toggle="tooltip" title="">
    <el-tooltip
      v-if="info.type == 'contract' || info.type == 'token'"
      class="item"
      effect="dark"
      style="margin-end: 0.2rem"
      :content="info.type == 'contract' ? 'Contract' : 'Token'"
      placement="top"
    >
      <i
        class="far fa-file-alt text-secondary"
        data-toggle="tooltip"
        data-boundary="viewport"
        data-html="true"
        title=""
        data-original-title="Contract"
      ></i>
    </el-tooltip>

    <!-- show this is create a contract -->
    <el-tooltip
      v-if="info.type == 'createContract'"
      class="item"
      effect="dark"
      style="margin-end: 0.2rem"
      :content="'New Contract: ' + info.address"
      placement="top"
    >
      <a
        href="#"
        class="text-truncate hash-tag"
        @click.prevent="showContract(info.address)"
      >
        <i class="far fa-newspaper text-secondary"></i>
        [Contract Creation]
      </a>
    </el-tooltip>

    <!-- show address (address, contract, token) -->
    <el-tooltip
      v-else-if="info.type != 'createContract' && address != currentAddress"
      class="item"
      effect="dark"
      :disabled="longAddress"
      :content="address"
      placement="top"
    >
      <a
        v-if="info.type == 'address' || info.type == 'coin'"
        href="#"
        @click.prevent="showAddress(address)"
      >
        {{ info.memo ? info.memo : parseLongText(address) }}</a
      >
      <a v-else href="#" @click.prevent="showContract(address)">
        {{ info.memo ? info.memo : parseLongText(address) }}</a
      >
    </el-tooltip>

    <span
      v-else-if="info.type != 'createContract' && address == currentAddress"
      class="text-truncate myFnExpandBox_searchVal"
      >{{ info.memo ? info.memo : parseLongText(address) }}
    </span>
  </span>
</template>

<script>
import route from "../../router/index";
import { parseLongText } from "../../../src/assets/js/utils.js";
export default {
  props: {
    currentAddress: "",
    address: "",
    info: { logo: "", memo: "" },
    longAddress: false,
  },
  methods: {
    parseLongText(str, start = 10, end = 6) {
      if (!this.longAddress) {
        return parseLongText(str, start, end);
      } else {
        return str;
      }
    },
    showAddress: function (addr) {
      // location.href = "/address?a=" + addr;
      route.push({
        path: "/address",
        query: {
          a: addr,
        },
      });
      // this.reload();
    },

    showContract(contract) {
      // location.href = "/token/index.html?c=" + contract;
      route.push({
        path: "/TokenDetail",
        query: {
          c: contract,
        },
      });
    },
  },
};
</script>
