<template>
  <el-tooltip
    class="item"
    effect="dark"
    style="margin-end: 0.1rem"
    :content="hash"
    placement="top"
  >
    <a
      class="hash-tag text-truncate myFnExpandBox_searchVal"
      href="javascript:void(0)"
      @click.prevent="showTransaction(hash)"
      >{{ parseLongText(hash) }}</a
    >
  </el-tooltip>
</template>
<script>
import route from "../../router/index";
import { parseLongText } from "../../../src/assets/js/utils.js";

export default {
  props: {
    hash: "",
  },
  methods: {
    showContract(contract) {
      route.push({
        path: "/TokenDetail",
        query: {
          c: contract,
        },
      });
    },
    parseLongText(str, start = 10, end = 6) {
      return parseLongText(str, start, end);
    },
    showTransaction(hash) {
      route.push({
        path: "/txDetail",
        query: {
          h: hash,
        },
      });
    },
  },
};
</script>
